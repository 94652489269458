import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import LogRocket from 'logrocket';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://2d6f3ba2ac8640eab72f027961d68f8b@o402599.ingest.sentry.io/6566414",
    integrations: [],
  });
  LogRocket.init('jymlud/talk-dfat-to-me');
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
