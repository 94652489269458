import React from "react";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import i18n from "i18next";
import { useTranslation, initReactI18next, Trans } from "react-i18next";



import './App.css';
import Editor from './Editor'
import Footer from './Footer'


let theme = createTheme({
  palette: {
    primary: {
      light: '#e254ff',
      main: '#aa00ff',
      dark: '#7200ca',
      contrastText: '#fff',
    },
    mode: 'dark'
  },
})
theme = responsiveFontSizes(theme);

i18n
  .use(initReactI18next)
  .init({
    resources: {
      dfat: {
        translation: {
          'Talk': 'Leveraging',
          'to me': 'reports for building resilient grant writing capacity',
          'Much innovation, very SDG 9. An app that writes in the style of DFAT reports.': 'Finding common ways of working through the benefits of innovative technology.'
        }
      }
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });



function App() {
  useTranslation();
  const [isHelpOpen, setOpenHelp] = React.useState(false);


  return (
    <ThemeProvider theme={theme}>
      <Box className="App-main" sx={{ py: 5 }}>
        <Container>
          <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
            <Trans>Talk</Trans> <Box color="primary.main" component="span">DFAT</Box> <Trans>to me</Trans>
          </Typography>
          <Typography variant="body1" sx={{ my: 2 }}>
            <Trans>Much innovation, very SDG 9. An app that writes in the style of DFAT reports.</Trans>
          </Typography>
          <Box sx={{ mt: 8 }}>
            <Editor setOpenHelp={setOpenHelp}></Editor>
          </Box>
        </Container>
      </Box>
      <Footer isHelpOpen={isHelpOpen} setOpenHelp={setOpenHelp}></Footer>
    </ThemeProvider>
  );
}

export default App;
