import React from "react";
import i18n from "i18next";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import CloseLine from 'remixicon-react/CloseLineIcon'
import logo from './images/kangaroo_robot.jpg'
import demo_mp4 from './images/talk-dfat-demo_6.mp4'


const LANGUAGES = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "dfat",
    name: "DFAT",
  },
];

const modalStyle = {
  bgcolor: "background.paper",
  p: 4,
};

const modalTitleStyle = {
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  mb: 3,
};


const FAQDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const faqContent = [
    {
      question: "Can I use this to write actual grants?",
      answer: "Probably not! While the model is good at generating text that looks like grant applications, it's not factually accurate, and it lacks coherence at the document-level.",
    },
    {
      question: "Why DFAT?",
      answer: 'Only because they are the donor I personally have most experience with! And because "Talk DFAT to me" works better than "Talk USAID to me".',
    },
    {
      question: "How does this work?",
      answer: "The backend for this website is based on a text generation AI model that was fine-tuned on a range of documents from dfat.gov.au. The selected text is used as model input, and the model output is used to expand your text.",
    },
  ];

  return (
    <Dialog
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth="md"
      open={props.open}
      onClose={props.onClose}
    >
      <Box sx={modalStyle}>
        <Box sx={modalTitleStyle}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            FAQ
          </Typography>
          <IconButton onClick={props.onClose}>
            <CloseLine></CloseLine>
          </IconButton>
        </Box>
        {faqContent.map((faqItem, i) => {
          return (
            <Box key={i}>
              <Typography variant="h6">{faqItem.question}</Typography>
              <Typography sx={{ mt: 1, mb: 3 }}>{faqItem.answer}</Typography>
            </Box>
          );
        })}
        <Box>
          <Typography variant="h6">I work for DFAT. I don’t want to read grant applications written by an AI. What do you think you’re doing?</Typography>
          <Typography sx={{ mt: 1, mb: 3 }}>
            I’m sorry, I just couldn’t resist. Maybe you need an AI to help review and score grant applications… 
            <Link href="https://twitter.com/RaphaelMerx/" target="_blank">reach out?</Link>
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

const HelpDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth="md"
      open={props.open}
      onClose={props.onClose}
    >
      <Box sx={modalStyle}>
        <Box sx={modalTitleStyle}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Help
          </Typography>
          <IconButton onClick={props.onClose}>
            <CloseLine></CloseLine>
          </IconButton>
        </Box>
        <Box>
          <Typography sx={{ mb: 2 }}>
            Select text from a dot point or sentence, click "Expand", voilà:
          </Typography>
          <video controls autoPlay style={{
              width: '100%',
              maxHeight: '100vh',
            }}>
            <source src={demo_mp4} type="video/mp4" />

          </video>
          <Typography sx={{ mb: 2 }}>
            Please note: more context will yield better results. Enter a relevant title in the first line, and try to select at least 5 words to expand.
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

export default function AppFooter(props) {
  const [langCode, setLangCode] = React.useState("en");
  const [isFAQopen, setOpenFAQ] = React.useState(false);

  const setLanguage = (e) => {
    const selectedLangCode = e.target.value;
    setLangCode(selectedLangCode);
    i18n.changeLanguage(selectedLangCode);
  };

  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: (theme) => theme.palette.grey[900] }}
    >
      <Container
        sx={{
          my: 8,
          display: "flex",
          color: (theme) => theme.palette.grey[100],
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} md={2} sx={{ display: {sm: 'flex', xs: 'none'} }}>

            <Tooltip title="«An illustration of a kangaroo shaking hands with a robot» by DALL-E">
            <Box
              component="img"
              sx={{
                height: '100%',
                width: 120,
              }}
              src={logo}
            ></Box>
            </Tooltip>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h6" marked="left" gutterBottom>
              About
              <span className="bar"></span>
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                <FAQDialog
                  open={isFAQopen}
                  onClose={() => {
                    setOpenFAQ(false);
                  }}
                ></FAQDialog>
                <Link href="#" onClick={() => setOpenFAQ(true)}>
                  FAQ
                </Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <HelpDialog
                  open={props.isHelpOpen}
                  onClose={() => {
                    props.setOpenHelp(false);
                  }}
                ></HelpDialog>
                <Link href="#" onClick={() => props.setOpenHelp(true)}>
                  Help
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Typography variant="h6" marked="left" gutterBottom>
              Language
            </Typography>
            <span className="bar"></span>
            <Select
              size="medium"
              variant="standard"
              sx={{ mt: 1, width: 150 }}
              onChange={setLanguage}
              value={langCode}
            >
              {LANGUAGES.map((language) => (
                <MenuItem value={language.code} key={language.code}>
                  {language.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
