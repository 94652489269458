import Button from '@mui/material/Button';

import axios from 'axios';

import React from "react";

import './Editor.scss'
import { API_ENDPOINT } from './utils'


const ExpandButton = (props) => {

  const getSelectedText = () => {
    const { view, state } = props.editor
    let { from, to } = view.state.selection
    let text = state.doc.textBetween(from, to, ' ').trim();

    // get a bit more words from the text before if 7 words or less
    if (text.split(' ').length < 8) {
      // arbitrary: get the 100 chars before current selection, then get the last 7 words
      from = Math.max(from - 100, 0)
      text = state.doc.textBetween(from, to, ' ').trim()
      text = text.split(' ').splice(-7).join(' ')
    }

    return {text: text, position: to}
  }

  const buildWordPositionChain = (startPosition, text) => {
    /* Return an array of [positionOfWord, wordToInsert] for each word in text */
    const words = text.split(' ');
    let position = startPosition;
    return words.map(word => {
      const toReturn = [position, word + ' ']
      position += word.length + 1;
      return toReturn
    })
  }

  const insertText = (editor, text, position) => {
    /* Insert text at position, one word every 100 ms. */
    const wordPositionChain = buildWordPositionChain(position, text)
    wordPositionChain.forEach(([position, word], i) => {
      setTimeout(() => {
        editor
          .chain()
          .focus()
          .insertContentAt(position, word)
          .run()
      }, i * 100)
    })
  }

  const getContext = () => {
    /* Return the first 2000 words of this document. */
    const docText = props.editor.view.dom.innerText;
    return (docText.split(' ')).slice(0, 2000).join(" ")
  }

  const handleButton = () => {
    // text the text
    let {text, position} = getSelectedText()
    // only keep the last 256 words
    text = (text.split(' ')).splice(-256).join(" ")

    let title = props.editor.view.domAtPos(1)['node'].textContent
    title = (title.split(' ')).slice(0, 30).join(" ") // first 30 words

    // send that as prompt to api
    props.setLoading(true)
    localStorage.setItem('hasExpandedBefore', true)
    props.callback()
    axios.post(API_ENDPOINT, {
        q: text,
        context: getContext(),
        title: title,
        mode: 'completion'
    }).then((response) => {
      props.setLoading(false)
      let textToInsert = response.data.result;
      insertText(props.editor, textToInsert, position)
      return response.data.result;
    }).catch((error) => {
      props.setLoading(false)
      // eslint-disable-next-line no-alert
      window.alert(error);
    });
  }

  return (
    <Button variant="contained" onClick={handleButton}>
      Expand
    </Button>
  );
}

export default ExpandButton
