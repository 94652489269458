import Box from '@mui/material/Box';
import Link from "@mui/material/Link";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Help from 'remixicon-react/QuestionLineIcon';


import Blockquote from '@tiptap/extension-blockquote'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Code from '@tiptap/extension-code'
import CodeBlock from '@tiptap/extension-code-block'
import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Gapcursor from '@tiptap/extension-gapcursor'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import History from '@tiptap/extension-history'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Italic from '@tiptap/extension-italic'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Strike from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text'

import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react'
import React, { useState } from "react";

import MenuBar from './MenuBar'
import ExpandButton from './ExpandButton';
import EditButton from './EditButton';

import './Editor.scss'


let initialContent = localStorage.getItem('editor-content') || '';
if (initialContent) {
  try {
    initialContent = JSON.parse(initialContent)
  } catch (error) {
    initialContent = ''
  }
} else {
  initialContent = JSON.parse('{"type":"doc","content":[{"type":"heading","attrs":{"level":3},"content":[{"type":"text","text":"Key lessons learned for the Improving Education in Papua New Guinea program"}]},{"type":"bulletList","content":[{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"Take a partnership approach in working with the Government"}]}]},{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"Flexible planning with a long-term focus"}]}]},{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"MEL with a focus on tangible outcomes"}]}]},{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"Capacity building of key education management staff"}]}]}]}]}')
}

const CustomDocument = Document.extend({
  content: 'heading block*',
})

const Editor = (props) => {
  const editor = useEditor({
    extensions: [
      Blockquote,
      Bold,
      BulletList,
      Code,
      CodeBlock,
      CustomDocument,
      Dropcursor,
      Gapcursor,
      HardBreak,
      Heading,
      History,
      HorizontalRule,
      Italic,
      ListItem,
      OrderedList,
      Paragraph,
      Strike,
      Text,
    ],
    content: initialContent,
    onUpdate: ({ editor }) => {
      localStorage.setItem('editor-content', JSON.stringify(editor.getJSON()));
    },
  })

  const [showHelp, setShowHelp] = useState(!localStorage.getItem('hasExpandedBefore'))
  const [loading, setLoading] = useState(false)

  const expandCallback = () => {
    setShowHelp(false)
  }

  return (
    <Box sx={{ height: "100%" }}>
      {showHelp && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            mb: 1,
            fontSize: "0.75rem",
          }}
        >
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            <Help size={16}></Help>
          </Box>
          <Box sx={{ ml: 1 }}>
            Select some text then click "Expand" to automatically continue writing.
            {' '}
            <Link href="#" onClick={() => props.setOpenHelp(true)}>
              Show me
            </Link>
          </Box>
        </Box>
      )}
      {editor && (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100, zIndex: 1000, placement: "bottom-end" }}>
          <ExpandButton
            editor={editor}
            callback={expandCallback}
            setLoading={setLoading}
          ></ExpandButton>
          {/*
          <EditButton
            editor={editor}
            callback={expandCallback}
            setLoading={setLoading}
          ></EditButton>
          */}
        </BubbleMenu>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className="editor-container">
        <MenuBar editor={editor} />
        <EditorContent editor={editor} />
      </Box>
    </Box>
  );
}

export default Editor
