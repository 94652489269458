import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";


import ArrowGoForwardLine from 'remixicon-react/ArrowGoForwardLineIcon';
import ArrowGoBackLine from 'remixicon-react/ArrowGoBackLineIcon';
import Strikethrough from 'remixicon-react/StrikethroughIcon';
import Italic from 'remixicon-react/ItalicIcon';
import Bold from 'remixicon-react/BoldIcon';
import H1 from 'remixicon-react/H1Icon';
import H2 from 'remixicon-react/H2Icon';
import H3 from 'remixicon-react/H3Icon';
import H4 from 'remixicon-react/H4Icon';
import ParagraphIcon from 'remixicon-react/ParagraphIcon';
import ListUnorderedIcon from 'remixicon-react/ListUnorderedIcon';
import ListOrderedIcon from 'remixicon-react/ListOrderedIcon';
import DoubleQuotesLIcon from 'remixicon-react/DoubleQuotesLIcon';

import React from "react";

import './Editor.scss'


const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  return (
    <div className="editor__header">
      <Tooltip title="Bold">
        <IconButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <Bold></Bold>
        </IconButton>
      </Tooltip>
      <Tooltip title="Italic">
        <IconButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <Italic></Italic>
        </IconButton>
      </Tooltip>
      <Tooltip title="Strikethrough" sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <IconButton
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          <Strikethrough></Strikethrough>
        </IconButton>
      </Tooltip>
      <Box className="divider" sx={{ display: { xs: 'none', sm: 'flex' } }}></Box>
      <Tooltip title="Heading 1">
        <IconButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          <H1></H1>
        </IconButton>

      </Tooltip>
      <Tooltip title="Heading 2">
        <IconButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          <H2></H2>
        </IconButton>

      </Tooltip>
      <Tooltip title="Heading 3" sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <IconButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        >
          <H3></H3>
        </IconButton>

      </Tooltip>
      <Tooltip title="Heading 4" sx={{ display: { xs: 'none', md: 'flex' } }}>
        <IconButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        >
          <H4></H4>
        </IconButton>

      </Tooltip>
      <Tooltip title="Paragraph">
        <IconButton
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
        >
          <ParagraphIcon></ParagraphIcon>
        </IconButton>

      </Tooltip>
      <Box className="divider" sx={{ display: { xs: 'none', sm: 'flex' } }}></Box>
      <Tooltip title="Bullet list" sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <IconButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <ListUnorderedIcon></ListUnorderedIcon>
        </IconButton>

      </Tooltip>
      <Tooltip title="Ordered list" sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <IconButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          <ListOrderedIcon></ListOrderedIcon>
        </IconButton>

      </Tooltip>
      <Tooltip title="Quote block" sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <IconButton
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          <DoubleQuotesLIcon></DoubleQuotesLIcon>
        </IconButton>

      </Tooltip>
      <Box className="divider"></Box>
      <Tooltip title="Undo">
        <IconButton  onClick={() => editor.chain().focus().undo().run()}>
          <ArrowGoBackLine></ArrowGoBackLine>
        </IconButton>
      </Tooltip>
      <Tooltip title="Redo">
        <IconButton  onClick={() => editor.chain().focus().redo().run()}>
          <ArrowGoForwardLine></ArrowGoForwardLine>
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default MenuBar;
